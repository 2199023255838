@import 'npm:aos/dist/aos.css';

@import 'colors';
@import 'animations';

::selection {
  background-color: $secondary;
  color: #ffffff;
}

body {
  font-family: Barlow, sans-serif;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.btn-primary {
  &,
  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $secondary;
    border-color: $secondary;
  }

  &:hover {
    background-color: rgba($secondary, 0.75);
    border-color: rgba($secondary, 0.75);
  }

  &:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
  }
}

.bg-secondary {
  background-image: linear-gradient(-225deg, $primary-light1 0%, $primary-light2 100%) !important;
}

a {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $secondary;
    text-decoration: none;
    //font-weight: 500;
  }
  /*
  &:hover {
    text-decoration: none;
  }
  */
}

.djnd-link {
  text-decoration: none;

  img {
    max-height: 50px;
  }

  &:hover {
    text-decoration: underline;
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  font-style: italic;
  color: $secondary;
  margin-bottom: 5rem;

  strong {
    font-weight: 500;
  }
}

.spaced-container {
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;

  &-md {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-sm {
    padding-top: 3.1rem;
    padding-bottom: 3.1rem;
  }
}

.page-padding {
  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 767.98px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar {
  padding: 1rem 0;

  .navbar-brand {
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;

    &,
    &:hover,
    &:active {
      color: $secondary;
    }
  }

  .nav-item {
    font-size: 1rem;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1.5rem;
    text-decoration: none;
    font-style: italic;
    color: #000000;

    &:last-of-type {
      margin-right: 0;
    }

    &.nav-link.active {
      color: $secondary;
      font-weight: 500;
    }
  }

  .custom-select {
    border-radius: 0;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3e%3cpath fill="%23#{str-slice("#{secondary}", 2)}" d="M12 15L8 9h8z"/%3e%3c/svg%3e');
    background-size: contain;
    background-position: right -0.15rem center;
    padding-right: 2rem;
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 100px;

    @media (max-width: 767.98px) {
      margin-left: 0;
      font-size: 1rem;
    }
  }
}

.jumbotron {
  padding-top: 6rem;
  padding-bottom: 7.8rem;
  margin-bottom: 0;
  color: $secondary;
  // min-height: calc(100vh - 10rem);

  @media (max-width: 767.98px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &.jumbotron-about {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background-image: url('../img/consul-ekipa-slika.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  h1 {
    font-weight: 700;
    font-size: 3.5rem;
    font-style: italic;

    strong {
      font-weight: 500;
    }
  }

  p {
    font-size: 1.5rem;
  }
}

.jumbo-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 150%;
  background-image: url('../img/site-landing@2x.png');
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 991.98px) {
    position: static;
    width: 80%;
    padding-top: 80%;
    margin: 0 auto;
  }
}

.about-us-content {
  .about-us-lead {
    p {
      font-size: 1.4rem;
    }
  }

  p {
    color: $secondary;
    font-size: 1.2rem;
  }

  @media (min-width: 992px) {
    .about-img-1 {
      position: absolute;
      top: 10%;
      left: -2.5rem;
      z-index: -1;
    }

    .about-img-2 {
      position: absolute;
      top: 5%;
      left: -2.5rem;
    }
  }
}

.midsection {
  h3 {
    font-weight: 500;
  }

  h2 {
    margin-bottom: 0;
    font-style: normal;
  }
}

.lead-boxes {

  .lead-box {
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;

    &.lead-box--last {
      @media (max-width: 767.98px) {

        .lead-box-image,
        hr {
          display: none;
        }
      }
    }

    .lead-box-icon {
      height: 250px;

      @media (max-width: 767.98px) {
        height: 150px;
      }

      img {
        height: 100%;
      }
    }

    .lead-box-text {
      padding: 1.5rem;
      box-shadow: 0 0 1.5rem rgba(26, 29, 64, 0.2);
      flex: 1;
    }

    .lead-box-image {
      margin: -1.5rem -1.5rem 1.5rem -1.5rem;

      .img-fluid {
        width: 100%;
      }
    }

    h3 {
      font-size: 1.8rem;
      font-weight: 700;
      font-style: italic;
      margin-top: 1rem;

      em {
        font-weight: 700;
      }
    }

    hr {
      width: 45%;
      height: 5px;
      border: 0;
      background-image: linear-gradient(to right, $primary 0%, #d9dbf1 100%);
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      margin-left: 0;
    }

    p {
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.4;
    }
  }
}

/*
.wild-title {
  @media (max-width: 767.98px) {
    font-size: 2rem;
  }
}
*/

.showcase-container {
  margin-bottom: 6.2rem;
  background-image: linear-gradient(to right, #dcdef3 0%, #f9f9fc 100%);

  .showcase {
    .showcase-boxes {

      & > div {
        margin-bottom: 1.5rem;
      }

      .row {
        overflow-x: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        overflow-y: hidden;
      }

      .row::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }

      .showcase-box {
        border: 4px solid $secondary;
        text-decoration: none;
        color: #000;
        position: relative;
        height: 180px;
        //max-width: 400px;
        transition: background-color 0.2s ease;

        @media (max-width: 991.98px) {
          margin-left: auto;
          margin-right: auto;
          margin-top: 2rem;
        }

        &:hover {
          background-color: $secondary;
          p {
            color: white;
          }
        }

        .showcase-box-icon {
          div {
            height: 60px;
            width: 60px;
            background-color: white;
            border-radius: 50%;
          }

          img {
            max-height: 65%;
            max-width: 65%;
          }
        }

        p {
          width: 100px;
          font-size: 1.2rem;
          line-height: 1.2rem;
          font-weight: 400;
          color: $secondary;
          margin-bottom: 0;
        }

        .showcase-box-button {
          position: absolute;
          bottom: -0.7rem;
          right: 0.75rem;
          background-color: #7c7fae;
          border-radius: 1rem;
          font-size: 0.75rem;
          color: #ffffff;
          padding: 0.25rem 1rem;
        }
      }
    }

    .showcase-boxes-more-btn {
      cursor: pointer;
      //background-color: $secondary;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      flex-shrink: 0;

      svg {
        width: 2rem;
        height: 2rem;
        fill: white;
      }

      .bi-arrow-left {
        opacity: 0;
        transition: opacity 0.2s ease;
        position: absolute;
      }
      .bi-arrow-right {
        opacity: 1;
        transition: opacity 0.6s ease;
        position: static;
      }

      &.scrolled {
        .bi-arrow-left {
          opacity: 1;
          transition: opacity 0.6s ease;
          position: static;
        }
        .bi-arrow-right {
          opacity: 0;
          transition: opacity 0.2s ease;
          position: absolute;
        }
      }

      span {
        color: $secondary;
        font-size: 1.5rem;
      }

      &:hover {
        //background-color: $secondary;
        span {
          color: white;
        }
        svg {
          fill: white;
        }

      }
    }
  }
}

.carousel {
  .images {
    display: flex;

    .image {
      display: flex;
      align-items: center;
      position: absolute;

      img {
        opacity: 0;
        transition: opacity 1s ease;
        width: 100%;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  dl {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;

    @media (max-width: 991.98px) {
      height: auto !important;
    }

    .carousel-text-element {
      margin-bottom: 3rem;
      border-left: solid 4px;
      border-right: 0;
      border-image: linear-gradient(to bottom, #f0b9ad, #d9dbf1) 0 100%;

      @media (max-width: 991.98px) {
        border: none;
      }
    }

    dt {
      margin-left: 1rem;
      margin-bottom: 1rem;
      transition: margin-bottom 1s ease;
      font-size: 1.8rem;
      line-height: 1;
      font-weight: 700;
      position: relative;
      color: $secondary;
      cursor: pointer;

      @media (min-width: 991.99px) {
        margin-bottom: 0;

        &.active {
          margin-bottom: 0.5rem;
        }

        &.active, &:hover {
          color: #181a34;
        }
      }
    }

    dd + dt {
      margin-top: 1.5rem;
    }

    dd {
      margin-left: 1rem;
      margin-bottom: 0;
      font-size: 1.2rem;
      font-weight: 300;
      overflow: hidden;
      height: 0;
      transition: height 1s ease;

      & > div {
        .image {
          margin: 2rem 0;
          text-align: center;
        }
      }

      @media (max-width: 991.98px) {
        height: auto;
      }
    }

    dt.active + dd {
      height: auto;
    }
  }
}

.contact-info {

  .contact-icon {
    background-color: #f7e5e1;
    height: 10rem;
    width: 10rem;
    min-width: 10rem;
    border-radius: 50%;

    svg {
      height: 50%;
    }
  }

  h3 {
    font-weight: 700;
    color: $secondary;
  }

  p {
    color: $secondary;
    font-weight: 300;

    a {
      color: $secondary;
      text-decoration: underline;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.contact-form {
  .form-group {
    position: relative;

    label {
      position: absolute;
      top: -0.9rem;
      left: .75rem;
      font-size: 1.1rem;
      font-weight: 300;
      padding: 0 .5rem;
      background-color: $primary;
    }

    .form-control {
      background: transparent;
      border-radius: 0;
      padding: 1.75rem 1.25rem;
      margin-top: 2rem;
      color: #fff;
      font-weight: 300;
    }
  }

  .btn {
    &,
    &:hover,
    &:not(:disabled):not(.disabled):active {
      padding: 0.4rem 2rem;
      color: #fff;
      background-color: rgba(#fff, 0.2);
      line-height: 1;
      vertical-align: bottom;
    }

    &:hover {
      background-color: rgba(#fff, 0.4);
    }

    &:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem rgba(#fff, 0.5);
    }

    .btn-icon {
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.25rem;
    }
  }
}

.footer {

  & > .row {
    @media (max-width: 991.98px) {
      max-width: 400px;
      margin: 0 auto;
    }

    .footer-col:not(:last-of-type) {
      @media (max-width: 991.98px) {
        border-bottom: 2px solid $primary;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  p {
    font-weight: 300;
    font-size: 1.1rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .heart {
    display: inline-block;
    width: 22px;
    height: 20px;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="%23#{str-slice("#{$secondary}", 2)}"%3e%3cpath d="M88.037 17.963A23.7 23.7 0 0 0 71.226 11a23.699 23.699 0 0 0-16.81 6.963L50 22.379l-4.416-4.416A23.699 23.699 0 0 0 28.774 11a23.7 23.7 0 0 0-16.811 6.963c-9.284 9.284-9.284 24.337 0 33.621L50 89.621l38.037-38.037c9.284-9.284 9.284-24.337 0-33.621"/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: center;
  }

  .btn.github {
    display: inline-block;
    padding: 0 1.5rem .15rem 1.5rem;
    line-height: 2rem;
    color: #fff;

    // IE 10
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 50%;
    }

    svg {
      height: 13px;
    }
  }

  .logo {
    a {
      display: block;
      width: 100%;
    }
  }

  .part-of img {
    max-height: 4rem;
  }
}

.sm-text-align-center {
  @media (max-width: 767.98px) {
    text-align: center;
  }
}
